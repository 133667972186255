


























import { Component } from "vue-property-decorator";
import { Guid } from 'guid-typescript';
import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { TechnologyStore } from './store';
import { TechnologyModel } from '@/services/iResourceService';

import PgaTechnologyDetails from './Components/PgaTechnologyDetails.vue';

@Component({
	components:
	{
		PgaTechnologyDetails
	}
})
export default class PgaTechnologyPageDetails extends mixins(SharedMixin)
{
	private showDialog = false;
	private title = "";
	private message = "";

	get TechnologyDetails() : TechnologyModel 
    {
		return TechnologyStore.TechnologyDetails;
	}

	get IsHandleable() : boolean 
    {
		return TechnologyStore.TechnologyDetails.Handleable;
	}
	
	ShowDialogDelete(): void
	{
		this.title = "Deleting Technology";
		this.message = `Are you sure you want to delete the Technology '${this.TechnologyDetails.Description}'?`;
		this.showDialog = true;
	}

	HideDialog(): void
	{
		this.showDialog = false;
		this.title = "";
		this.message = "";
	}
    
	Edit(): void
	{
		if (!this.TechnologyDetails)
		{
			console.log("view model is null");
			return;
		}
		
		const id = this.TechnologyDetails.Id.toString();
		this.$router.push({ name: 'technologyedit', params: { id }});
	}

	async Delete(): Promise<void>
	{
		try
		{
			if (this.TechnologyDetails)
				await TechnologyStore.DeleteTechnology(this.TechnologyDetails.Id);
			this.$router.push({ path: '/technologies' });
		}
		finally
		{
			this.showDialog = false;
			this.title = "";
			this.message = "";
		}
	}

	async mounted() : Promise<void>
	{
		TechnologyStore.DropTechnologyDetails();
		const id: string = this.$route.params.id;
		await TechnologyStore.GetTechnologyDetails(Guid.parse(id));
	}
}

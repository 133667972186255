
























































import { Component, Model } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import SharedMixin from '@/mixins/SharedMixin';

import { TechnologyStore } from "../store";
import { CertificationTableModel, EditTechnologyCommand, TechAreaModel } from "@/services/iResourceService";

@Component({})
export default class PgaTechnologyEdit extends mixins(SharedMixin) {
	
	@Model("", { type: Object }) readonly Model!: EditTechnologyCommand;

	get code(): number
	{
		const code = TechnologyStore.TechnologyEdit?.ViewModel?.Code ?? 0;
		return Number.parseInt(code.toString());
	}

	get description(): string
	{
		return TechnologyStore.TechnologyEdit?.ViewModel?.Description ?? "";
	}
	set description(desription: string)
	{
		TechnologyStore.SET_TECHNOLOGY_EDIT_DESCRIPTION(desription);
	}

	get techAreas(): Array<TechAreaModel>
	{
		return TechnologyStore.TechnologyEdit?.ViewModel?.TechAreas ?? new Array<TechAreaModel>();
	}
	set techAreas(value: Array<TechAreaModel>)
	{
		TechnologyStore.SET_TECHNOLOGY_EDIT_TECHAREAIDS(value.map(e => e.Id));
	}

	get certifications(): Array<CertificationTableModel>
	{
		return TechnologyStore.TechnologyEdit?.ViewModel?.Certifications ?? new Array<CertificationTableModel>();
	}
	set certifications(value: Array<CertificationTableModel>)
	{
		TechnologyStore.SET_TECHNOLOGY_EDIT_CERTIFICATIONIDS(value.map(c => c.Id));
	}

	get Errors(): Map<string, Array<string>>
	{
		return TechnologyStore.TechnologyEdit.Errors;
	}

	get techAreasDropDown(): Array<TechAreaModel>
	{
		return TechnologyStore.TechAreasDropDown;
	}
	get certificationsDropDown(): Array<CertificationTableModel>
	{
		return TechnologyStore.CertificationsDropDown;
	}

	async mounted(): Promise<void>
	{
		await TechnologyStore.GetTechAreasDropDown();
		await TechnologyStore.GetCertificationsDropDown();
	}
}

import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators'
import store from '@/infrastructure/store'
import { Guid } from 'guid-typescript';

import pgaDiContainer from '@/App.container';
import IResourceService from '@/services/iResourceService';
import IOrderService from '@/services/iOrderService';
import { TechnologyModel, ListTechnologies, TechnologyTableModel, TechAreaModel, CertificationTableModel,
        CreateTechnologyCommand, EditTechnologyCommand,
        CreateTechnologyResponse, EditTechnologyResponse, DeleteTechnologyResponse } from '@/services/iResourceService';

import ResourceService from '@/services/ResourceService';
import OrderService from '@/services/OrderService';
import { SharedModule } from '@/feature/Shared/store';

export interface TechnologyState
{
    TechnologiesTable: ListTechnologies;
    TechnologyDetails: TechnologyModel | null;
    TechnologyCreate: CreateTechnologyModel;
    TechnologyEdit: EditTechnologyModel;
    TechnologyDelete: DeleteTechnologyModel;

    TechAreasDropDown: Array<TechAreaModel>;
    CertificationsDropDown: Array<CertificationTableModel>;
}

export interface CreateTechnologyModel
{
    Command: CreateTechnologyCommand | null;
    Response: CreateTechnologyResponse | null;
    Errors: Map<string, Array<string>>;
}

export interface EditTechnologyModel
{
    ViewModel: TechnologyModel | null;
    Command: EditTechnologyCommand | null;
    Response: EditTechnologyResponse | null;
    Errors: Map<string, Array<string>>;
}

export interface DeleteTechnologyModel
{
    Id: Guid;
    Response: DeleteTechnologyResponse | null;
}

@Module({ namespaced: true, dynamic: true, store, name: 'TechnologyModule', })
class TechnologiesStore extends VuexModule implements TechnologyState
{
    _resourceService: IResourceService = pgaDiContainer.get<IResourceService>(ResourceService);
    _orderService: IOrderService = pgaDiContainer.get<IOrderService>(OrderService);

    public TechnologiesTable: ListTechnologies =
    {
        Technologies: new Array<TechnologyTableModel>(),
    };

    public TechnologyDetails: TechnologyModel =
    {
        Id: Guid.createEmpty(),
        Code: 0,
        Description: '',
        TechAreas: new Array<TechAreaModel>(),
        Certifications: new Array<CertificationTableModel>(),
        ModifiedBy:
        {
            Id: Guid.createEmpty(),
            FullName: '',
        },
        ModifiedDate: new Date(),
        Handleable: false,
    };

    public TechnologyCreate: CreateTechnologyModel =
    {
        Command: null,
        Response: null,
        Errors: new Map<string, Array<string>>()
    };

    public TechnologyEdit: EditTechnologyModel =
    {
        ViewModel: null,
        Command: null,
        Response: null,
        Errors: new Map<string, Array<string>>()
    };

    public TechnologyDelete: DeleteTechnologyModel =
    {
        Id: Guid.createEmpty(),
        Response: null,
    }


    public TechAreasDropDown: Array<TechAreaModel> = new Array<TechAreaModel>();
    public CertificationsDropDown: Array<CertificationTableModel> = new Array<CertificationTableModel>();

    // DropDown TechAreas
    @Action({ rawError: true })
    public async GetTechAreasDropDown()
    {
        const techAreas = await this._orderService.GetAllTechAreasDropdownRestricted();
        this.SET_TECHAREAS_DROPDOWN(techAreas.TechAreas);
    }
    @Mutation
    public SET_TECHAREAS_DROPDOWN(techAreas: Array<TechAreaModel>)
    {
        this.TechAreasDropDown = techAreas;
    }

    @Action({ rawError: true })
    public async GetCertificationsDropDown()
    {
        const certifications = await this._resourceService.GetAllCertificationsDropdown();
        this.SET_CERTIFICATIONS_DROPDOWN(certifications.Certifications);
    }
    @Mutation
    public SET_CERTIFICATIONS_DROPDOWN(certifications: Array<CertificationTableModel>)
    {
        this.CertificationsDropDown = certifications;
    }

    // List Technologies
    @Action({ rawError: true })
    public async GetAllTechnologies()
    {
        const technologies = await this._resourceService.GetAllTechnologies();
        this.SET_TECHNOLOGIES(technologies);
    }
    @Mutation
    SET_TECHNOLOGIES(technologies: ListTechnologies)
    {
        this.TechnologiesTable = technologies;
    }

    // Details Technology
    @Action({ rawError: true })
    public async GetTechnologyDetails(id: Guid)
    {
        SharedModule.SET_ISLOADING(true);
        const technology: TechnologyModel = await this._resourceService.GetTechnology(id);
        this.SET_TECHNOLOGY_DETAILS(technology);
        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public DropTechnologyDetails()
    {
        const resetTechnologyDetails: TechnologyModel =
        {
            Id: Guid.createEmpty(),
            Code: 0,
            Description: '',
            TechAreas: new Array<TechAreaModel>(),
            Certifications: new Array<CertificationTableModel>(),
            ModifiedBy:
            {
                Id: Guid.createEmpty(),
                FullName: '',
            },
            ModifiedDate: new Date(),
            Handleable: false,
        };
        this.SET_TECHNOLOGY_DETAILS(resetTechnologyDetails);
    }
    @Mutation
    public SET_TECHNOLOGY_DETAILS(technology: TechnologyModel)
    {
        this.TechnologyDetails = technology;
    }
    @Mutation
    public SET_TECHNOLOGY_DETAILS_ID(id: Guid)
    {
        this.TechnologyDetails.Id = id;
    }
    @Mutation
    public SET_TECHNOLOGY_DETAILS_DESCRIPTION(description: string)
    {
        this.TechnologyDetails.Description = description;
    }
    @Mutation
    public SET_TECHNOLOGY_DETAILS_TECHAREAS(techAreas: Array<TechAreaModel>)
    {
        this.TechnologyDetails.TechAreas = techAreas;
    }
    @Mutation
    public SET_TECHNOLOGY_DETAILS_CERTIFICATIONS(certifications: Array<CertificationTableModel>)
    {
        this.TechnologyDetails.Certifications = certifications;
    }

    // Create Technology
    @Action({ rawError: true })
    public async CreateTechnology(): Promise<CreateTechnologyResponse>
    {
        try {
            let response: CreateTechnologyResponse = { Id: Guid.createEmpty() };
            SharedModule.SET_ISLOADING(true);
            
            if (this.TechnologyCreate.Command)
                response = await this._resourceService.CreateTechnology(this.TechnologyCreate.Command);

            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_TECHNOLOGY_CREATE_ERRORS(errs);

            throw errs;
        }
    }
    @Action({ rawError: true })
    public InitTechnologyCreate()
    {
        const command: CreateTechnologyCommand =
        {
            Description: '',
            TechAreaIds: new Array<Guid>(),
            CertificationIds: new Array<Guid>(),
        }

        this.SET_TECHNOLOGY_CREATE(command);
    }
    @Action({ rawError: true })
    public async DropTechnologyCreate()
    {
        this.SET_TECHNOLOGY_CREATE(null);
    }
    @Mutation
    public SET_TECHNOLOGY_CREATE(command: CreateTechnologyCommand | null)
    {
        this.TechnologyCreate.Command = command;
    }
    @Mutation
    public SET_TECHNOLOGY_CREATE_DESCRIPTION(description: string)
    {
        if (this.TechnologyCreate.Command)
            this.TechnologyCreate.Command.Description = description;
    }
    @Mutation
    public SET_TECHNOLOGY_CREATE_TECHAREAIDS(techAreaIds: Array<Guid>)
    {
        if (this.TechnologyCreate.Command)
            this.TechnologyCreate.Command.TechAreaIds = techAreaIds;
    }
    @Mutation
    public SET_TECHNOLOGY_CREATE_CERTIFICATIONIDS(certificationIds: Array<Guid>)
    {
        if (this.TechnologyCreate.Command)
            this.TechnologyCreate.Command.CertificationIds = certificationIds;
    }
    @Mutation
    public SET_TECHNOLOGY_CREATE_ERRORS(errs: Map<string, Array<string>>)
    {
        this.TechnologyCreate.Errors = errs;
    }

    // Edit Technology
    @Action({ rawError: true })
    public async EditTechnology() : Promise<EditTechnologyResponse>
    {
        try {
            let response: EditTechnologyResponse = { Id: Guid.createEmpty() };
            SharedModule.SET_ISLOADING(true);
            
            if (this.TechnologyEdit.Command)
                response = await this._resourceService.EditTechnology(this.TechnologyEdit.Command.Id, this.TechnologyEdit.Command);
            
            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_TECHNOLOGY_EDIT_ERRORS(errs);

            throw errs;
        }
    }
    @Action({ rawError: true })
    public async GetTechnologyToEdit(id: Guid)
    {
        SharedModule.SET_ISLOADING(true);

        this.SET_TECHNOLOGY_EDIT_COMMAND(null);
        this.SET_TECHNOLOGY_EDIT_VIEWMODEL(null);

        const technology: TechnologyModel = await this._resourceService.GetTechnology(id);

        this.SET_TECHNOLOGY_EDIT_VIEWMODEL(technology);
        this.InitTechnologyEdit();

        this.SET_TECHNOLOGY_EDIT_ID(technology.Id);
        this.SET_TECHNOLOGY_EDIT_DESCRIPTION(technology.Description);
        this.SET_TECHNOLOGY_EDIT_TECHAREAIDS(technology.TechAreas.map(ta => ta.Id));
        this.SET_TECHNOLOGY_EDIT_CERTIFICATIONIDS(technology.Certifications.map(c => c.Id));

        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public InitTechnologyEdit()
    {
        const command: EditTechnologyCommand =
        {
            Id: Guid.createEmpty(),
            Description: '',
            TechAreaIds: new Array<Guid>(),
            CertificationIds: new Array<Guid>(),
        }

        this.SET_TECHNOLOGY_EDIT_COMMAND(command);
    }
    @Mutation
    public SET_TECHNOLOGY_EDIT_COMMAND(command: EditTechnologyCommand | null)
    {
        this.TechnologyEdit.Command = command;
    }
    @Mutation
    public SET_TECHNOLOGY_EDIT_VIEWMODEL(viewModel: TechnologyModel | null)
    {
        this.TechnologyEdit.ViewModel = viewModel;
    }
    @Mutation
    public SET_TECHNOLOGY_EDIT_ID(id: Guid)
    {
        if (this.TechnologyEdit.Command)
            this.TechnologyEdit.Command.Id = id;
    }
    @Mutation
    public SET_TECHNOLOGY_EDIT_DESCRIPTION(description: string)
    {
        if (this.TechnologyEdit.Command)
            this.TechnologyEdit.Command.Description = description;
    }
    @Mutation
    public SET_TECHNOLOGY_EDIT_TECHAREAIDS(techAreaId: Array<Guid>)
    {
        if (this.TechnologyEdit.Command)
            this.TechnologyEdit.Command.TechAreaIds = techAreaId;
    }
    @Mutation
    public SET_TECHNOLOGY_EDIT_CERTIFICATIONIDS(certificationIds: Array<Guid>)
    {
        if (this.TechnologyEdit.Command)
            this.TechnologyEdit.Command.CertificationIds = certificationIds;
    }
    @Mutation
    public SET_TECHNOLOGY_EDIT_ERRORS(errs: Map<string, Array<string>>)
    {
        this.TechnologyEdit.Errors = errs;
    }

    // Delete Technology
    @Action({ rawError: true })
    public async DeleteTechnology(id: Guid): Promise<DeleteTechnologyResponse>
    {
        try {
            let response: DeleteTechnologyResponse = { Id: Guid.createEmpty() };
            SharedModule.SET_ISLOADING(true);
            this.SET_TECHNOLOGY_DELETE(id);

            if (this.TechnologyDelete)
                response = await this._resourceService.DeleteTechnology(this.TechnologyDelete.Id);
            SharedModule.SET_ISLOADING(false);
            return response
        }
        catch(error) {
            SharedModule.SET_ISLOADING(false);

            const errs = parseErrors(error);
            const message = errs.get('id')?.map(e => e = `- ${e}`).join(" <br> ") ?? "";
            SharedModule.SET_ALERT_ERROR_MESSAGE(message);
            SharedModule.SET_ALERT_IS_VISIBLE(true);

            throw error;
        }
    }
    @Mutation
    public SET_TECHNOLOGY_DELETE(id: Guid)
    {
        this.TechnologyDelete.Id = id;
    }
}

function parseErrors(error: any): Map<string, Array<string>> {
    const errs = new Map<string, Array<string>>();
    for (const [key, value] of Object.entries(error.response.data)) {
        const messages = value as Array<string>;
        errs.set(key, messages);
    }
    return errs;
}

export const TechnologyStore = getModule(TechnologiesStore);
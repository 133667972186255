

























import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import SharedMixin from '@/mixins/SharedMixin';

import { TechnologyTableModel } from '@/services/iResourceService';

@Component({})
export default class PgaTechnologiesTable extends mixins(SharedMixin)
{
    @Prop({ default: function() { return new Array<TechnologyTableModel>() } }) readonly TechnologiesList!: Array<TechnologyTableModel>;
    @Prop({ default: true }) readonly isLoading!: boolean;

    headers: Array<any> =
    [
		{
			text: 'Code',
			align: 'start',
			sortable: true,
            value: 'Code',
            width: "10%",
            keySearch: "co",
        },
        {
			text: 'Description',
			align: 'start',
			sortable: true,
			value: 'Description',
            keySearch: "de",
        },
        {
			text: 'Tech Areas',
			align: 'start',
			value: 'TechAreas',
            keySearch: "ta",
        },
        {
			text: 'Certifications',
			align: 'start',
			value: 'Certifications',
            keySearch: "ce",
        },
    ];

    rowClicked (item: any) : void
    {
		const id = item.Id;
		this.$router.push({ name: 'technologydetails', params: { id }});
	}
}
















import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { TechnologyStore, CreateTechnologyModel } from './store';
import PgaTechnologyCreate from './Components/PgaTechnologyCreate.vue';
import { CreateTechnologyResponse } from '@/services/iResourceService';

@Component({
	components:
	{
		PgaTechnologyCreate
	}
})
export default class PgaTechnologyPageCreate extends mixins(SharedMixin)
{
	get TechnologyCreate() : CreateTechnologyModel 
	{
		return TechnologyStore.TechnologyCreate;
	}

	mounted() : void
	{
		TechnologyStore.InitTechnologyCreate();
	}

    async Create() : Promise<void>
    {
        try 
        {
			const response: CreateTechnologyResponse = await TechnologyStore.CreateTechnology();
			const id: string = response.Id.toString();
			this.$router.push({ name: 'technologydetails', params: { id }});
		}
        // eslint-disable-next-line no-empty
        catch(error) { }
	}

    undo() : void 
    {
		this.$router.go(-1);
	}

	beforeDestroy() : void
	{
		TechnologyStore.DropTechnologyCreate();
		TechnologyStore.TechnologyCreate.Errors = new Map<string, Array<string>>()
	}
}

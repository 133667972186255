



















































import { Component, Model } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from '@/mixins/SharedMixin';

import { TechnologyStore } from "../store";
import { CertificationTableModel, CreateTechnologyCommand, TechAreaModel } from "@/services/iResourceService";

@Component({})
export default class PgaTechnologyCreate extends mixins(SharedMixin)
{
	@Model("", { type: Object }) readonly Model!: CreateTechnologyCommand;

	get description(): string
	{
		return this.Model?.Description ?? "";
	}
	set description(desription: string)
	{
		TechnologyStore.SET_TECHNOLOGY_CREATE_DESCRIPTION(desription);
	}

	get techAreaIds() : Array<TechAreaModel>
	{
		return new Array<TechAreaModel>();
	}
	set techAreaIds(techAreas: Array<TechAreaModel>)
	{
		TechnologyStore.SET_TECHNOLOGY_CREATE_TECHAREAIDS(techAreas.map(ta => ta.Id));
	}

	get certificationIds() : Array<CertificationTableModel>
	{
		return new Array<CertificationTableModel>();
	}
	set certificationIds(certifications: Array<CertificationTableModel>)
	{
		TechnologyStore.SET_TECHNOLOGY_CREATE_CERTIFICATIONIDS(certifications.map(c => c.Id));
	}

	get Errors(): Map<string, Array<string>>
	{
		return TechnologyStore.TechnologyCreate.Errors;
	}

	get techAreasDropDown(): Array<TechAreaModel>
	{
		return TechnologyStore.TechAreasDropDown;
	}
	get certificationsDropDown(): Array<CertificationTableModel>
	{
		return TechnologyStore.CertificationsDropDown;
	}


	async mounted(): Promise<void>
	{
		await TechnologyStore.GetTechAreasDropDown();
		await TechnologyStore.GetCertificationsDropDown();
	}
}

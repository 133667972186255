














import { Component } from "vue-property-decorator";
import { TechnologyStore } from './store';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaTechnologiesTable from './Components/PgaTechnologiesTable.vue';
import { TechnologyTableModel } from '@/services/iResourceService';

@Component({
    components:
    {
        PgaTechnologiesTable
    }
})
export default class PgaTechnologyPageIndex extends mixins(SharedMixin)
{
    private isLoading = true;

    get TechnologiesList() : Array<TechnologyTableModel>
    {
        return TechnologyStore.TechnologiesTable.Technologies;
    }
    
    async mounted() : Promise<void>
    {
        this.isLoading = true;
        await TechnologyStore.GetAllTechnologies();
        this.isLoading = false;        
    }
}
